@import '../../../variables';

@keyframes blink-primary {
  0% {
    background-color: $primary-pale;
  }
  100% {
    background-color: $primary-light;
  }
}
@keyframes blink-border {
  0% {
    border: 1px solid rgba(0, 0, 0, 0.6);
  }
  100% {
    border: 1px solid rgba(0, 0, 0, 0.1);
  }
}

@keyframes scale-down-base {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(0.95);
  }
}

@keyframes scale-down-roof {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(0.97);
  }
}

.keycap-base {
  &.drag-over {
    border-color: $primary-pale;
    .keycap {
      background-color: $primary-pale !important;
      border-color: $primary !important;
    }
    .keyroof-base {
      border-color: $primary-pale !important;
      background-color: $primary-pale !important;
    }
    .keyroof {
      background-color: $primary-pale !important;
    }
  }
  &.keycap-selected {
    .keycap {
      background: $primary-light !important;
      border-color: $primary-pale !important;
    }
    .keyroof-base {
      border-color: $primary-thin !important;
      background-color: $primary-pale !important;
    }
    .keyroof {
      background-color: $primary-pale !important;
    }
  }
  .keycap {
    cursor: pointer;
    &.keycap-test-matrix {
      cursor: default;
      &:hover {
        border: 1px solid rgba(0, 0, 0, 0.6);
        animation: none;
      }
    }

    &.keycap-test-matrix-down {
      transform-origin: center;
      animation-duration: 300ms;
      animation-name: scale-down-base;
      animation-fill-mode: forwards;
    }

    &.keycap-border {
      border: 1px solid rgba(0, 0, 0, 0.6);
      transition: box-shadow 0.2s;
    }
    border-radius: 5px;
    font-size: 12px;
  }

  .keycap:hover,
  .keycap:hover ~ .keycap2 {
    // border: 1px solid rgba(0, 0, 0, 0.1);
    // animation: blink-border 0.3s;
    box-shadow: inset 0 0 2px #000000;
  }

  .keycap2:hover {
    border: 1px solid rgba(0, 0, 0, 0.1);
    animation: blink-border 0.3s;
  }

  .keyroof-base {
    border: 2px solid rgba(255, 255, 255, 0.9);
    border-radius: 3px;
    pointer-events: none;
    cursor: pointer;
    &.test-matrix {
      border-width: 1px;
    }
    &.test-matrix-down {
      border-width: 0px;
      transform-origin: center;
      animation-duration: 300ms;
      animation-name: scale-down-roof;
      animation-fill-mode: forwards;
    }
  }

  .keyroof {
    display: flex;
    width: 100%;
    height: 100%;
    border-radius: 2px;
    flex-direction: column;
    box-sizing: border-box;
    background-clip: padding-box;
    pointer-events: none;
    cursor: pointer;

    .keylabel {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      pointer-events: none;
      position: relative;
      // color: #333;
      flex-grow: 1;
      &.vcenter {
        align-items: center;
      }
      &.vbottom {
        align-items: flex-end;
      }
      .label {
        position: absolute;
        font-size: 0.7rem;
        line-height: 1.1;
        width: 110%;
        overflow-wrap: anywhere;

        &.debug {
          width: 100%;
          padding: 0px;
        }
      }
      ._m {
        font-size: 0.7rem;
      }
      ._s {
        font-size: 0.6rem;
      }
      .keycode-label {
        font-weight: 500;
        // color: $color-gray-600;
      }
      .modifier {
        // color: $color-gray-600;
        font-size: 0.6rem;
        width: 124%;
      }
      .left {
        text-align: left;
        padding-left: $space-xs;
      }
      .center {
        text-align: center;
      }
      .right {
        text-align: right;
        padding-right: $space-m;
        // color: $color-gray-500;
      }
    }
    .color-modifier {
      background-color: #c8c8c8 !important;
    }
    .color-accent {
      background-color: #808080 !important;
    }
    .diff-dot {
      position: absolute;
      top: 8px;
      right: 8px;
    }
  }
  .pointer-pass-through {
    pointer-events: none;
  }
}
