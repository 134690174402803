@import '../../../variables';

.keyboard-wrapper {
  position: relative;
  width: 100%;
  margin-top: calc(var(--key-height));
  z-index: 1;
  overflow-y: hidden;
  .controller {
    display: flex;
    flex-direction: row;
    padding: $space-l;
    background-color: white;
    .switch {
      display: flex;
      margin-left: auto;
    }
  }
  .keymap {
    display: flex;
    flex-direction: column;
    background-color: white;
  }
  .macro {
    display: flex;
    flex-direction: column;
    padding-bottom: 32px;
    background-color: white;
  }
}
.keycode {
  display: flex;
  position: relative;
  flex-direction: column;
  padding: $space-m $space-m $space-xl $space-m;
  background-color: $background-color-gray;
  flex: 1;

  .disable {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8);
    margin-left: -16px;
    margin-top: -16px;
  }
}

.keycode-desc {
  display: flex;
  width: 100%;
  padding: $space-s $space-l;

  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  bottom: 0;
  color: white;
  z-index: 3;
  .keycode-desc-label {
  }
  .keycode-desc-detail {
    margin-left: auto;
  }
}
